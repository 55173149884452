// import logo from '../images/logo.png';

// import axios from "axios";
let config = {}
// config['BASE_URL'] = 'http://localhost:3000';
// config['API_URL'] = 'http://localhost:3001/api/';
// config['API_TOKEN'] = 'Bearer 744b365cde7bd714a928d5a04167a117'; 

// config['BASE_URL'] = 'https://api-hrms.dtsmis.in:3008/v1';
// config['API_URL'] = 'http://localhost:8080/v1/admin/';
// config['FRONT_URL'] = 'https://front-hrms.dtsmis.in/';
// config['API_TOKEN'] = 'Bearer 744b365cde7bd714a928d5a04167a117';
// config['IMAGE_PATH'] = 'https://api-hrms.dtsmis.in:3008/public/uploads/';


// config['LOGO_PATH'] = logo;

// config['COMPANY_NAME'] = 'HRMS WEB APP';  
// config['PANEL_NAME'] = 'HR Login';
// config['HELP_URL'] = 'support';
// config['PRIVACY_URL'] = 'privacy-policy';
// config['TERMS_URL'] = 'terms-conditions';

// export default config;


// import logo from '../images/logo.png';

// let config = {}

config['BASE_URL'] = 'https://hrapi.hlfppt.org/v1';
config['API_URL'] = 'https://hrapi.hlfppt.org/v1/admin/';
config['IMAGE_PATH'] = 'https://hrapi.hlfppt.org/public/uploads/';
config['FRONT_URL'] = 'https://jobs.hlfppt.org/';
config['API_TOKEN'] = 'Bearer 744b365cde7bd714a928d5a04167a117';
// config['LOGO_PATH'] = logo;
config['COMPANY_NAME'] = 'HRMS WEB APP';  
config['PANEL_NAME'] = 'HRMS Login';
config['HELP_URL'] = 'support';
config['PRIVACY_URL'] = 'pivacy-policy';
config['TERMS_URL'] = 'terms-conditions';

// config['BASE_URL'] = 'https://api-hrms.dtsmis.in:3008/v1';
// // config['API_URL'] = 'http://localhost:8080/v1/admin/';
// config['FRONT_URL'] = 'https://front-hrms.dtsmis.in/';

// Duplex Fron URL
// config['FRONT_URL'] = 'https://career.duplextech.com';
// config['BASE_URL'] = 'https://apis.duplextech.com:3018/v1';
// config['API_URL'] = ' https://apis.duplextech.com:3018/v1/admin/';
// config['GLOB_API_URL'] = 'https://apis.duplextech.com:3018/v1/global/';
// config['IMAGE_PATH'] = 'https://apis.duplextech.com:3018/public/uploads/';
// config['API_TOKEN'] = 'Bearer 744b365cde7bd714a928d5a04167a117';
// config['FRONT_URL'] = ' https://career.duplextech.com';
// config['COMPANY_NAME'] = 'Duplex Technologies Services Pvt. Ltd ';  
// config['PANEL_NAME'] = 'Candidate Login';
// config['HELP_URL'] = 'https://duplextech.com/contact-us.html';
// config['PRIVACY_URL'] = 'https://duplextech.com/privacy-policy.html';
// config['TERMS_URL'] = 'https://duplextech.com/terms-and-conditions.html';

export default config;



